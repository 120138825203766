<template>

<app-content-head :title="item.name" :image="item.image">

	<app-content-head-actions-item :divideAfter="true" :text="(hasGeolocation) ? 'Find' : 'Locate'" :primary="hasGeolocation" :loading="is.geosaving" :icon="(hasGeolocation) ? 'geolocated' : 'geolocation'" v-on:click="onGeolocationClick" v-if="item.session.can.geolocate" />
		
	<app-content-head-actions-item text="Shortlist" :loading="is.shortlisting" icon="shortlist" v-on:click="onShortlistClick" v-if="item.session.can.shortlist" />
	<app-content-head-actions-item text="Shortlist" :active="true" :loading="is.shortlisting" icon="shortlisted" v-on:click="onUnshortlistClick" v-if="item.session.can.unshortlist" />

	<app-content-head-actions-item text="Join" :danger="item.session.is.clashing" :loading="is.seating" :icon="(item.session.is.clashing) ? 'clash' : 'join'" v-on:click="onSitClick" v-if="item.session.can.join && !isTicketed" />
	<app-content-head-actions-item text="Leave" :loading="is.seating" icon="leave" v-on:click="onStandClick" v-if="item.session.can.leave && !isTicketed" />

	<app-content-head-actions-item text="Accept" icon="accept" v-on:click="onAcceptClick" v-if="item.session.can.accept" />
	<app-content-head-actions-item text="Reject" icon="reject" :danger="true" v-on:click="onRejectClick" v-if="item.session.can.reject" />

	<app-content-head-actions-item text="Manage" :divide="!item.session.is.hosting && !item.session.can.geolocate" icon="manage" v-on:click="onManageClick" v-if="item.session.can.manage" />
	<app-content-head-actions-item icon="clone" text="Clone" v-on:click="onCloneClick" v-if="$isModerator || item.session.can.clone" />
	<app-content-head-actions-item text="Cancel" icon="cancel" :danger="true" v-on:click="onCancelClick" v-if="item.session.can.cancel" />

</app-content-head>

</template>

<script>

export default {

	props: ['item'],

	data: function() {

		return {
			is: {
				shortlisting: false,
				seating: false
			}
		}

	},

	computed: {

		hasGeolocation: function() {

			return this.item.geolocation

		},

		isTicketed: function() {

			return this.item.ticket

		}

	},

	methods: {

		onGeolocationClick: function() {

			this.$emit('geolocation')

		},

		onAcceptClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event.Accept',
				params: {
					id: this.item.id
				}
			})

		},

		onRejectClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event.Reject',
				params: {
					id: this.item.id
				}
			})

		},

		onCloneClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Submit',
				query: {
					clone: this.item.id
				}
			})

		},

		onManageClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event.Manage',
				params: {
					id: this.item.id
				}
			})

		},

		onCancelClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event.Cancel',
				params: {
					id: this.item.id
				}
			})

		},

		onSitClick: function() {

			if (this.item.session.is.clashing) {

				this.$router.push({
					name: 'Convention.Schedule.Event.Join'
				})

			} else {

				this.is.seating = true

				this.$api.post(['convention/schedule/event', this.item.id, 'seat']).then(function() {

					this.is.seating = false

					this.$notify({
						message: ['You have successfully taken a seat for the event.']
					})

				}.bind(this))

			}

		},

		onStandClick: function() {

			this.is.seating = true

			this.$api.delete(['convention/schedule/event', this.item.id, 'seat']).then(function() {

				this.is.seating = false

				this.$notify({
					message: ['You have successfully left your seat for the event.']
				})

			}.bind(this))

		},

		onShortlistClick: function() {

			this.is.shortlisting = true

			this.$api.post(['convention/schedule/event', this.item.id, 'shortlist']).then(function() {

				this.is.shortlisting = false

				this.$notify({
					message: ['You have successfully added the event to your shortlist.']
				})

			}.bind(this))

		},

		onUnshortlistClick: function() {

			this.is.shortlisting = true

			this.$api.delete(['convention/schedule/event', this.item.id, 'shortlist']).then(function() {

				this.is.shortlisting = false

				this.$notify({
					message: ['You have successfully removed the event from your shortlist.']
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>